import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  container: {
    padding: '0 40px 60px 40px',
    ['@media (max-width:550px)']: {
      padding: '20px 20px 40px 20px',
    },
  },
  claimButton: {
    marginLeft: '8px',
    minWidth: '50px',
    height: '24px',
    fontSize: '0.8rem',
    padding: 0,
    '&.Mui-disabled': {
      border: `solid 1px ${colors.oneSecondColor}!important`,
      color: `${colors.oneSecondColor}!important`,
      opacity: '50%'
    },
    '&:active': {
      background: 'transparent',
      opacity: 0.3,
    },
    '@media (max-width: 670px)': {
      marginLeft: 0,
      marginRight: '16px',
    },
  },
  baseGrid: {
    textAlign: 'center',
    color: colors.white,
    alignItems: 'center',
    flexDirection: 'column',
  },
  loaderBox: {
    color: `${colors.oneSecondColor}!important`,
    padding: '20px 0 20px 0',
  },
  loaderButton: {
    color: `${colors.oneSecondColor}!important`,
    width: '22px!important',
    height: '22px!important',
  },
  formBox: {
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  textFieldInput: {
    color: `${colors.oneSecondColor}!important`,
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  textFieldNotchedOutline: {
    borderWidth: '1px',
    borderColor: `${colors.oneSecondColor}!important`,
    borderRadius: '12px',
  },
  textFieldLabel: {
    color: `${colors.oneSecondColor}!important`,
    "&.Mui-focused": {
      color: `${colors.oneSecondColor}!important`,
      fontWeight: "bold",
    }
  },
  tabBox: {
    marginTop: '40px',
    width: '100%',
    border: 'solid 3px #5cf5cf',
    borderRadius: '16px',
    backgroundColor: '#04050f',
    color: 'white',
    justifyContent: 'space-between',
    maxWidth: '450px',
    alignItems: 'center',
    padding: '14px 28px!important',
    '@media (max-width: 670px)': {
      maxWidth: '350px',
    },
  },
  itemBoxVer: {
    display: 'flex',
    marginTop: '40px',
    width: '100%',
    border: 'solid 3px #5cf5cf',
    borderRadius: '16px',
    backgroundColor: '#04050f',
    color: 'white',
    justifyContent: 'space-between',
    maxWidth: '450px',
    alignItems: 'center',
    padding: '14px 28px!important',
    '@media (max-width: 670px)': {
      maxWidth: '350px',
    },
  },
  itemBoxHor: {
    display: 'flex',
    marginTop: '40px',
    width: '100%',
    border: 'solid 3px #5cf5cf',
    borderRadius: '16px',
    backgroundColor: '#04050f',
    color: 'white',
    justifyContent: 'space-between',
    maxWidth: '450px',
    padding: '14px 28px!important',
    '@media (max-width: 670px)': {
      maxWidth: '350px',
    },
  },
  textBox: {
    textAlign: 'left',
    marginTop: '10px',
    fontSize: '1.7rem',
    '@media (max-width: 670px)': {
      fontSize: '1.3rem',
    },
  },
  stakeButton: {
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: '1.05rem',
    lineHeight: '22px',
    borderRadius: '10px',
    padding: '10px',
    width: '100%',
    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: '#04050f',
      color: colors.white,
      opacity: 1,
    },
    '&:active': {
      background: 'transparent',
      opacity: 0.3,
    },
    '@media (max-width: 670px)': {
      width: '100%',
      marginRight: 0,
      margin: '16px 0',
    },
    border: `solid 1px ${colors.oneSecondColor}!important`,
    backgroundColor: colors.oneSecondColor,
    color: '#04050f',
    '&.Mui-disabled': {
      backgroundColor: colors.black,
      border: `solid 1px ${colors.oneSecondColor}!important`,
      color: `${colors.oneSecondColor}!important`,
      opacity: '50%'
    }
  },
  maxButton: {
    position: 'relative',
    margin: 0,
    padding: 0,
    left: '140px',
    bottom: '40px',
    color: colors.oneSecondColor,
    '@media (max-width: 670px)': {
      left: '95px',
    },
  },
  slpLink: {
    '&:hover': {
      color: colors.white,
    },
    padding: 4,
    marginLeft: 60,
    '@media (max-width: 670px)': {
      marginLeft: 4,
    },
  }
});
