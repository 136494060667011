import { useEffect, useState } from 'react'
import IERC20 from '../../abi/IERC20.abi.json'
import MegaPool from '../../abi/MegaPool.abi.json'
import UniswapV2Pair from '../../abi/UniswapV2Pair.abi.json'

import { ChainToken } from 'helpers/types';
import { stakingContracts, lpContracts } from 'helpers/constants'


function useContractMap(chainTokens: ChainToken | null): Map<string, any> | undefined {
  const [ABIs, setABIs] = useState<Map<string, any> | undefined>(undefined);

  useEffect(() => {
    if (!chainTokens) return;

    const newABIs = new Map();

    for (const chainId of Object.keys(stakingContracts)) {
      newABIs.set(
        stakingContracts[chainId],
        MegaPool,
      )
    }

    for (const chainId of Object.keys(lpContracts)) {
      newABIs.set(
        lpContracts[chainId],
        UniswapV2Pair,
      )
    }

    for (const chainId of Object.keys(chainTokens)) {
      const tokens = chainTokens[chainId]

      for (const token of tokens) {
        newABIs.set(token.address, IERC20)
      }
    }
    setABIs(newABIs)
  }, [chainTokens])

  return ABIs;
}

export default useContractMap