import { useEffect, useState, useMemo } from 'react'
import {
  useQueries,
} from 'react-query'

import { DEFAULT_LIST_OF_LISTS } from 'helpers/tokenList'
import { ChainToken } from 'helpers/types';
import { getTokenList } from 'api/tokenList'


function useTokens(): ChainToken | null {
  const [tokens, setTokens] = useState<ChainToken | null>(null)

  const tokenQueries = useQueries(
    DEFAULT_LIST_OF_LISTS.map(token_list_url => {
      return {
        queryKey: ['token_list_url', token_list_url],
        queryFn: () => getTokenList(token_list_url),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        suspense: true,
      }
    }),
  )
  const isLoading = useMemo(() => tokenQueries.some(query => query.isLoading), [tokenQueries])

  useEffect(() => {
    if (isLoading) return

    const tokensToUpdate: ChainToken = {};

    tokenQueries.filter((response) => response.isSuccess).map((response) => {
      if (response.data) {
        for (const token of response.data) {
          if (!tokensToUpdate[token.chainId]) {
            tokensToUpdate[token.chainId] = []
          }
          tokensToUpdate[token.chainId].push(token)
        }
      }
    })
    setTokens(tokensToUpdate);
  }, [isLoading])

  return tokens
}

export default useTokens