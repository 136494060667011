import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { useAppSelector } from 'app/hooks'

import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { EthSWRConfig } from 'ether-swr'

import { useContractMap, useTokens } from 'helpers/hooks'
import { useStyles } from './styles'

import { DappHeader } from 'components/dapp_header'
import { LandingFooter } from '../landing'
import { TVLInfo, RewardInfo, UnsupportedInfo, IntroInfo } from './info'
import { LiquidityTab } from './liquidity'
import { UnsupportedModal } from 'components/modals/unsupported'
import { WalletInfoModal, WalletSelectModal } from 'components/modals/wallet'


export const LiquidityInfo = (): JSX.Element => {
  const { container, baseGrid } = useStyles()

  const isAuthorized = useAppSelector((state) => state.wallet.isAuthorized)
  const { chainId, library: provider, account, error } = useWeb3React<Web3Provider>()
  const tokens = useTokens()
  const ABIs = useContractMap(tokens)

  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  return (
    <>
      <EthSWRConfig
        value={{ web3Provider: provider, ABIs, refreshInterval: 5_000 }}
      >
        <Container className={container}>
          <>
            {tokens && ABIs && (
              <DappHeader tokens={tokens} />
            )}
          </>
        </Container>

        <Grid container spacing={3} className={baseGrid}>
          <Grid item xs={12}>
            <Typography variant="h3">Supply Liquidity to Earn Rewards</Typography>
            <Typography variant="subtitle1" style={{ maxWidth: '500px', marginTop: '20px', display: 'inline-flex' }}>
              Supply your liquidity tokens into one of our active liquidity mining programmes to start earning sOLT rewards.
            </Typography>
          </Grid>
          {tokens && ABIs && chainId && account && provider ? (
            <>
              <TVLInfo {...{ chainId, account, tokens, provider }} />
              <RewardInfo {...{ chainId, account, tokens, provider }} />
              <LiquidityTab {...{ chainId, account, tokens, provider }} />
            </>
          ) : (
            <>
              {isUnsupportedChainIdError ? (
                <UnsupportedInfo />
              ) : (
                <>
                  {!isAuthorized && <IntroInfo />}
                </>
              )}
            </>
          )}
        </Grid>
      </EthSWRConfig>
      <LandingFooter />
      <UnsupportedModal />
      <WalletSelectModal />
      <WalletInfoModal />
    </>
  )
}
