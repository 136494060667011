import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  errorButton: {
    backgroundColor: colors.darkRed + '!important',
    color: colors.white + '!important',
    borderColor: colors.brightRed + '!important',
  },
  balanceBlock: {
    marginRight: 0,
    color: colors.oneSecondColor + '!important',
    backgroundColor: colors.oneFirstColor + '!important',
    userSelect: 'text',
    '& > p': {
      textAlign: 'center'
    },
    '&:hover': {
      background: 'transparent',
      opacity: 1,
    },
    '&:active': {
      background: 'transparent',
      opacity: 1,
    },
  },
  networkInfoButton: {
    backgroundColor: colors.white,
    color: colors.oneFirstColor + '!important',
  },
  connectButton: {
    backgroundColor: colors.oneSecondColor,
    borderColor: colors.oneSecondColor + '!important',
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.oneFirstColor,
      opacity: 1,
    },
    width: 'auto',
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: '52px',
    padding: '35px 0',
    margin: 'auto',
    userSelect: 'none',
    fontFamily: 'GTWalsheim',
    maxWidth: '1200px',
    '@media (max-width: 900px)': {
      padding: '40px 0',
    },
    '@media (max-width: 670px)': {
      flexDirection: 'column',
      height: 'auto',
      padding: '20px 0',
    },
  },
  logoFirstName: {
    paddingLeft: '12px',
    fontSize: 32,
    color: 'white',
  },
  logoLastName: {
    fontSize: 32,
    color: 'white',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.4s',
    color: 'white',
    '&:hover': {
      background: 'transparent',
      opacity: 0.7,
    },
    '&:active': {
      background: 'transparent',
      opacity: 0.3,
    },
    '@media (max-width: 670px)': {
      marginBottom: '20px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 670px)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
  },
  blogButton: {
    borderColor: colors.mainGreen,
    borderWidth: '2px',
    color: colors.white,
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '22px',
    borderRadius: '10px',
    height: '49px',
    marginRight: '12px',
    width: '123px',
    transition: 'all 0.4s',
    '&:hover': {
      background: 'transparent',
      opacity: 0.7,
    },
    '&:active': {
      background: 'transparent',
      opacity: 0.3,
    },
    '@media (max-width: 670px)': {
      width: '100%',
      marginRight: 0,
      margin: '16px 0',
    },
  },
  appButton: {
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    color: colors.coolBlack,
    background: colors.oneSecondColor,
    border: 'none',
    width: '193px',
    height: '49px',
    lineHeight: '22px',
    borderRadius: '10px',
    transition: 'all 0.4s',
    '&:hover': {
      background: colors.oneSecondColor,
      opacity: 0.7,
    },
    '&:active': {
      background: colors.oneSecondColor,
      opacity: 0.3,
    },
    '@media (max-width: 670px)': {
      width: '100%',
    },
  },
  disabledAppButton: {
    background: colors.defaultGrey,
    color: `${colors.black} !important`,
    opacity: 1,
    border: 'none',
  },
  blogButtonContainer: {
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    '@media (max-width: 670px)': {
      width: '100%',
    },
  },
})
