import { useEffect, useMemo } from 'react'
import useEtherSWR from 'ether-swr'

import { useAppSelector, useAppDispatch } from 'app/hooks'
import { confirmReceipt, TxStatusType, TxType } from 'features/wallet/poolSlice'
import { BigNumber } from 'ethers'


function useMonitorPool(chainId: number): string[] {
  const dispatch = useAppDispatch()
  const txs = useAppSelector((state) => state.pool.txs)
  const pendingTxs = useAppSelector((state) => Object.keys(state.pool.txs).filter((hash) => {
    return state.pool.txs[hash].status === TxStatusType.PENDING && state.pool.txs[hash].chainId === chainId
  }))

  const params = useMemo(() => pendingTxs.map((hash) => ['getTransactionReceipt', hash]), [pendingTxs])

  const { data: responses } = useEtherSWR(params)

  useEffect(() => {
    if (!responses) return

    for (const response of responses) {
      if (!response) continue

      console.log('response', response)

      const hash: string = response.transactionHash
      const status: number = response.status
      const logs: Array<{ [data: string]: string }> = response.logs

      console.log(`[tx update] (${hash}), status - ${status}`)

      const tx = txs[hash]

      let amount: BigNumber | undefined

      if (tx.type === TxType.CLAIM) {
        const data = logs[0]?.data
        if (data) {
          amount = BigNumber.from(data)
        }
      }

      dispatch(confirmReceipt({
        hash, status, amount,
      }))
    }
  }, [responses])

  return pendingTxs
}

export default useMonitorPool