import { useCallback, useState } from "react"
import { useSnackbar } from "notistack"
import { Web3Provider } from '@ethersproject/providers'

import MegaPool from 'abi/MegaPool.abi.json'
import { stakingContracts } from "helpers/constants";
import { prepareContractData } from "helpers/utilities";

interface PoolRequestProps {
  chainId: number
  account: string
  provider: Web3Provider
}

interface TxPayload {
  method: string
  params?: Array<any>
  onSuccess?(txHash: string): void
  onError?(errMsg: string): void
}

interface PoolResponse {
  loading: boolean
  execTransaction(data: TxPayload): Promise<void>
}

function usePoolTransaction({ provider, chainId, account }: PoolRequestProps): PoolResponse {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const ethereum = provider.provider

  const execTransaction = useCallback(async ({ method, params, onSuccess, onError }: TxPayload) => {
    const contractAddress = stakingContracts[chainId]
    const data = await prepareContractData(contractAddress, MegaPool, method, params || [])
    const transactionParameters = {
      from: account,
      to: contractAddress,
      data,
      chainId
    }
    try {
      setLoading(true)
      const txHash = await ethereum.request?.({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      enqueueSnackbar(`Tx "${txHash}" has been submited!`, { variant: 'success' })
      onSuccess?.(txHash)
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'warning' })
      onError?.(errMsg)
    } finally {
      setLoading(false)
    }
  }, [chainId, account, provider])

  return { loading, execTransaction }
}

export default usePoolTransaction