import { Button, Container, Grid, Link } from '@material-ui/core'
import FirstBoxGif from 'assets/icons/landing/FirstBoxIcon.gif'
import GitbookIcon from 'assets/icons/landing/GitbookIcon.svg'
import GitIcon from 'assets/icons/landing/GitIcon.svg'
import LinkedInIcon from 'assets/icons/landing/LinkedInIcon.svg'
import SecondBoxIcon from 'assets/icons/landing/SecondBoxIconV2.svg'
import TelegramIcon from 'assets/icons/landing/TelegramIcon.svg'
import ThirdBoxIcon from 'assets/icons/landing/ThirdBoxIconV2.svg'
import TwitterIcon from 'assets/icons/landing/TwitterIcon.svg'
import { LandingHeader } from 'components/landing_header'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { PRIVACY_POLICY } from 'routes'
import useWindowDimensions from 'services/resizeManager'
import { useStyles } from './styles'

export const LandingFooter = (): JSX.Element => {
  const {
    footer,
    iconsMargin,
    privacyLink,
    blueLink,
  } = useStyles()
  return (
    <Grid container spacing={2} className={footer}>
      <Grid item xs={12}>
        <div style={{ textAlign: 'center', }}>
          <Link
            className={blueLink}
            target="_blank"
            rel="noreferrer"
            href="https://docs.oneledger.io"
          >
            <img className={iconsMargin} src={GitbookIcon} alt="gitbook-in-icon" />
          </Link>
          <Link className={blueLink} target="_blank" rel="noreferrer" href="https://github.com/Oneledger">
            <img className={iconsMargin} src={GitIcon} alt="github-in-icon" />
          </Link>
          <Link
            className={blueLink}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/oneledgertech/"
          >
            <img className={iconsMargin} src={LinkedInIcon} alt="linked-in-icon" />
          </Link>
          <Link className={blueLink} target="_blank" rel="noreferrer" href="https://t.me/oneledger">
            <img className={iconsMargin} src={TelegramIcon} alt="telegram-icon" />
          </Link>
          <Link className={blueLink} target="_blank" rel="noreferrer" href="https://twitter.com/OneLedgerTech">
            <img className={iconsMargin} src={TwitterIcon} alt="twitter-icon" />
          </Link>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <RouterLink className={privacyLink} target="_blank" rel="noopener noreferrer" to={PRIVACY_POLICY}>
            Privacy policy
          </RouterLink>
        </div>
      </Grid>
    </Grid>
  )
}

const Landing = (): JSX.Element => {
  const {
    container,
    firstContainer,
    firstLeftContainer,
    firstTitle,
    buttonContainer,
    button,
    firstImg,
    secondContainer,
    secondTopContainer,
    secondTopText,
    secondTopTitle,
    secondImg,
    thirdContainer,
    thirdLeftContainer,
    thirdLeftTitle,
    thirdLeftText,
    thirdImg,
  } = useStyles()

  const { width, height } = useWindowDimensions()

  return (
    <Container className={container}>
      <LandingHeader />
      <div style={{ marginTop: height < 750 ? '2%' : '7%' }} className={firstContainer}>
        <div className={firstLeftContainer}>
          <h2 className={firstTitle}>{'landing_first_block_title'.localized()}</h2>
          <Link
            rel="noreferrer"
            className={buttonContainer}
            target={'_blank'}
            href="https://docs.oneledger.io/docs/elp/getting-started"
          >
            <Button variant="outlined" classes={{ root: button }}>
              {'landing_first_block_button'.localized()}
            </Button>
          </Link>
        </div>
        <img
          width={width < 575 ? '80%' : '50%'}
          height={'100%'}
          className={firstImg}
          src={FirstBoxGif}
          alt={'tokens-icon'}
        />
      </div>
      <div className={secondContainer}>
        <img
          width={width < 575 ? '30%' : '30%'}
          className={secondImg}
          src={SecondBoxIcon}
          alt={'chart-icon'}
        />
        <div className={secondTopContainer}>
          <span className={secondTopText}>{'landing_second_block_text'.localized()}</span>
          <span className={secondTopTitle}>{'landing_second_block_title'.localized()}</span>
        </div>
      </div>
      <div className={thirdContainer}>
        <div className={thirdLeftContainer}>
          <span className={thirdLeftTitle}>{'landing_third_block_title'.localized()}</span>
          <span className={thirdLeftText}>{'landing_third_block_text'.localized()}</span>
        </div>
        <img
          width={width < 575 ? '30%' : '30%'}
          height={'100%'}
          className={thirdImg}
          src={ThirdBoxIcon}
          alt={'liquidity-icon'}
        />
      </div>
      <LandingFooter />
    </Container>
  )
}

export default Landing
