import React, { useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
} from '@material-ui/core'
import { useAppDispatch } from 'app/hooks'
import { closeModal, ModalType, openModal } from 'features/wallet/modalSlice'

import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import useEtherSWR from 'ether-swr'
import { ethers } from 'ethers';

import { LANDING } from 'routes'
import { useStyles } from './styles'
import HeaderLogo from 'assets/icons/headerIcon.svg'
import { getToken, getShortAddress } from 'helpers/utilities'

import { getChainData } from '../../helpers/utilities'
import { ChainToken } from 'helpers/types';
import useMonitorPool from 'helpers/hooks/useMonitorPool'


interface TokenProps {
  chainId: number;
  account: string;
  tokens: ChainToken;
}


const TokenBalance = ({ tokens, chainId, account }: TokenProps): JSX.Element => {
  const { web3Button } = useStyles()
  const soltToken = getToken(tokens, chainId, 'sOLT')

  const { data: balance } = useEtherSWR([soltToken.address, 'balanceOf', account]);
  const _ = useMonitorPool(chainId)

  // NOTE: Remove this hardcode
  const fixStyle = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }

  if (!balance) {
    return <Button className={web3Button} style={fixStyle} disabled>-</Button>
  }

  return (
    <Button className={web3Button} style={fixStyle} disabled>{+(Number(ethers.utils.formatUnits(balance, soltToken.decimals)).toFixed(2))} {soltToken.symbol}</Button>
  )
}


interface HeaderProps {
  tokens: ChainToken
}


const DappHeader = React.memo(({ tokens }: HeaderProps): JSX.Element => {
  const {
    wrap,
    logoContainer,
    logoFirstName,
    logoLastName,
    connectButton,
    networkInfoButton,
    buttonsContainer,
    web3Button,
    errorButton,
    web3ButtonGroup
  } = useStyles()
  const dispatch = useAppDispatch()
  const { chainId, account, error } = useWeb3React<Web3Provider>()
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError

  const currentNetwork = useMemo(() => getChainData(chainId), [chainId])

  const triggerConnectModal = () => dispatch(openModal(ModalType.CONNECTION_SELECT))
  const triggerUnsupportedModal = () => dispatch(openModal(ModalType.UNSUPPORTED))
  const triggerWalletInfoModal = () => dispatch(openModal(ModalType.WALLET_INFO))
  const history = useHistory()

  useEffect(() => {
    if (account) {
      dispatch(closeModal())
    }
  }, [account]);

  return (
    <>
      <header className={wrap}>
        <div onClick={() => history.push(LANDING)} className={logoContainer}>
          <img src={HeaderLogo} alt="header-logo" />  <span className={logoFirstName}>One</span><span className={logoLastName}>Ledger</span>
        </div>
        <div className={buttonsContainer}>
          <Button className={`${web3Button} ${networkInfoButton}`} disabled>
            {currentNetwork.name}
          </Button>
          {account && chainId ? (
            <ButtonGroup fullWidth color="secondary" aria-label="outlined secondary button group" className={web3ButtonGroup}>
              {tokens && <TokenBalance chainId={chainId} account={account} tokens={tokens} />}
              <Button className={web3Button} onClick={triggerWalletInfoModal}>
                {getShortAddress(account)}
              </Button>
            </ButtonGroup>
          ) : (
            <>
              {isUnsupportedChainIdError ? (
                <Button className={`${web3Button} ${errorButton}`} onClick={triggerUnsupportedModal}>
                  Wrong Network
                </Button>
              ) : (
                <Button onClick={triggerConnectModal} className={`${web3Button} ${connectButton}`} >Connect Wallet</Button>
              )}
            </>
          )}
        </div>
      </header>
    </>
  )
})

DappHeader.displayName = 'DappHeader'
export { DappHeader }
