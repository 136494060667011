export const UNSUPPORTED_LIST_URLS: string[] = []

const SYNDICATE_LIST = 'https://raw.githubusercontent.com/oneledger/syndicate-token-list/master/syndicate.token-list.json'
const SYNDICATE_EXPERIMENTAL_LIST = 'https://raw.githubusercontent.com/oneledger/syndicate-token-list/master/syndicate-experimental.token-list.json'
const UNISWAP_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  SYNDICATE_LIST,
  SYNDICATE_EXPERIMENTAL_LIST,
  UNISWAP_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [SYNDICATE_LIST, UNISWAP_LIST]
