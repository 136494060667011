import React from 'react'
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  Typography,
  IconButton
} from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeModal, ModalType } from 'features/wallet/modalSlice';

const useStyles = makeStyles({
  walletSelectModal: {
    borderRadius: '10px',
  },
})

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton focusRipple={false} aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const UnsupportedModal = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { walletSelectModal } = useStyles()

  const modalOpened = useAppSelector((state) => state.modal.opened && state.modal.type === ModalType.UNSUPPORTED)
  const handleClose = () => dispatch(closeModal())

  return (
    <Dialog
      open={modalOpened}
      onClose={handleClose}
      aria-labelledby="wrong-network-dialog-title"
      maxWidth={'xs'}
      fullWidth
      classes={{
        paper: walletSelectModal
      }}
    >
      <DialogTitle id="wrong-network-dialog-title" onClose={handleClose}>
        Wrong Network
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Please connect to the appropriate EVM network.
        </Typography>
      </DialogContent >
    </Dialog >
  )
}