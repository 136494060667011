import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BigNumber } from 'ethers'


export enum TxType {
  CLAIM = 1,
  DEPOSIT = 2,
  WITHDRAW = 3,
  EXIT = 4,
}

export enum TxStatusType {
  FAILED = 0,
  DONE = 1,
  PENDING = 2,
}

interface TxReceipt {
  hash: string
  status: number
  amount?: BigNumber
}


interface Tx {
  chainId: number
  hash: string
  type: TxType
  status: TxStatusType
  amount?: BigNumber
  timestamp: number
}

export interface TxInput {
  chainId: number
  hash: string
  amount?: BigNumber
}


export interface TxState {
  txs: {
    [hash: string]: Tx
  }
}


const initialState: TxState = {
  txs: {},
}


export const poolSlice = createSlice({
  name: 'pool',
  initialState,
  reducers: {
    claim: (state, action: PayloadAction<TxInput>) => {
      state.txs[action.payload.hash] = ({
        ...action.payload,
        type: TxType.CLAIM,
        status: TxStatusType.PENDING,
        timestamp: Math.floor(Date.now() / 1000),
      })
    },
    deposit: (state, action: PayloadAction<TxInput>) => {
      state.txs[action.payload.hash] = ({
        ...action.payload,
        type: TxType.DEPOSIT,
        status: TxStatusType.PENDING,
        timestamp: Math.floor(Date.now() / 1000),
      })
    },
    withdraw: (state, action: PayloadAction<TxInput>) => {
      state.txs[action.payload.hash] = ({
        ...action.payload,
        type: TxType.WITHDRAW,
        status: TxStatusType.PENDING,
        timestamp: Math.floor(Date.now() / 1000),
      })
    },
    exit: (state, action: PayloadAction<TxInput>) => {
      state.txs[action.payload.hash] = ({
        ...action.payload,
        type: TxType.EXIT,
        status: TxStatusType.PENDING,
        timestamp: Math.floor(Date.now() / 1000),
      })
    },
    confirmReceipt: (state, action: PayloadAction<TxReceipt>) => {
      if (action.payload.hash in state.txs) {
        state.txs[action.payload.hash].status = action.payload.status
        if (action.payload.amount) {
          state.txs[action.payload.hash].amount = action.payload.amount
        }
      }
    },
    clearReceipts: (state, action: PayloadAction<number>) => {
      const newTxs: { [hash: string]: Tx } = {}
      Object.keys(state.txs).filter((hash: string) => state.txs[hash].chainId !== action.payload).forEach((hash: string) => newTxs[hash])
      state.txs = newTxs
    },
  },
})


export const { claim, deposit, withdraw, exit, confirmReceipt, clearReceipts } = poolSlice.actions

export default poolSlice.reducer
