import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse';
import React from 'react'
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LANDING, PRIVACY_POLICY, LIQUIDITY_INFO } from 'routes'
import 'services/i18n'
import { SnackbarProvider } from 'notistack';

import { Web3ReactProvider } from '@web3-react/core'
import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from '@ethersproject/providers'
import MetamaskProvider from 'providers/MetamaskProvider'

import { colors, themeConfig } from 'utils/mui'
import Landing from './pages/landing'
import PrivacyPolicy from './pages/privacy_policy'
import { LiquidityInfo } from './pages/dapp'

import { store } from './app/store'

export const notifyError = (): unknown => toast.error('Email is not valid')
export const notifySuccess = (): unknown => toast.success('You are subscribed')


interface CustomExternalProvider extends ExternalProvider {
  request?: (request: { method: string, params?: Array<any> | any }) => Promise<any>
}


declare global {
  interface Window {
    ethereum: CustomExternalProvider;
  }
}

const useStyles = makeStyles({
  variantSuccess: { backgroundColor: colors.coolGreen },
  variantError: { backgroundColor: colors.darkRed },
})

const getLibrary = (provider: CustomExternalProvider | JsonRpcFetchFunc): Web3Provider => new Web3Provider(provider)
const queryClient = new QueryClient()

const App = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const classes = useStyles()

  const theme = createMuiTheme(themeConfig)
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider
          classes={{
            variantSuccess: classes.variantSuccess,
            variantError: classes.variantError,
          }}
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          preventDuplicate
          dense
          // iconVariant={{
          //   success: '✅',
          //   error: '✖️',
          //   warning: '⚠️',
          //   info: 'ℹ️',
          // }}
          TransitionComponent={Collapse}
        >
          <Router>
            <Switch>
              <Route exact path={LANDING} component={Landing} />
              <Route path={PRIVACY_POLICY} component={PrivacyPolicy} />
              <Web3ReactProvider getLibrary={getLibrary}>
                <MetamaskProvider>
                  <QueryClientProvider client={queryClient}>
                    <Route path={LIQUIDITY_INFO} component={LiquidityInfo} exact />
                  </QueryClientProvider>
                </MetamaskProvider>
              </Web3ReactProvider>
              <Redirect to={LANDING} />
            </Switch>
          </Router>
        </SnackbarProvider>
        <ToastContainer
          position="top-right"
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </MuiThemeProvider>
  )
}

export default App
