import { IChainData } from "./types";

const supportedChains: IChainData[] = [
  {
    name: "Ethereum",
    short_name: "eth",
    chain: "ETH",
    network: "mainnet",
    chain_id: 1,
    network_id: 1,
    rpc_url: "https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/eth/mainnet",
    explorer_url: "https://etherscan.io",
    native_currency: {
      symbol: "ETH",
      name: "Ethereum",
      decimals: "18",
      contractAddress: "",
      balance: ""
    }
  },
  {
    name: "Ropsten",
    short_name: "rop",
    chain: "ETH",
    network: "ropsten",
    chain_id: 3,
    network_id: 3,
    rpc_url: "https://speedy-nodes-nyc.moralis.io/6be9c674e3a0ba73d6ba649d/eth/ropsten",
    explorer_url: "https://ropsten.etherscan.io",
    native_currency: {
      symbol: "ETH",
      name: "Ethereum",
      decimals: "18",
      contractAddress: "",
      balance: ""
    }
  },
];

export default supportedChains;