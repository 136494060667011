import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export enum ModalType {
  UNSUPPORTED = 1,
  CONNECTION_SELECT = 2,
  WALLET_INFO = 3,
}

interface ModalState {
  opened: boolean
  type: ModalType
}

const initialState: ModalState = {
  opened: false,
  type: ModalType.CONNECTION_SELECT,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.opened = false
    },
    openModal: (state, action: PayloadAction<ModalType>) => {
      state.opened = true
      state.type = action.payload
    },
  },
})


export const { openModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
