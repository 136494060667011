import React, { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import { injected } from '../helpers/connectors'

import { useAppSelector, useAppDispatch } from 'app/hooks'
import { setLoaded, authorize, deauthorize } from 'features/wallet/walletSlice'


function MetamaskProvider({ children }: { children: JSX.Element }): JSX.Element {
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React()

  const loaded = useAppSelector((state) => state.wallet.loaded)
  const dispatch = useAppDispatch()

  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        dispatch(setLoaded(true))
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injected)
        }
        if (isAuthorized) {
          dispatch(authorize())
        } else {
          dispatch(deauthorize())
        }
      })
      .catch(() => {
        dispatch(setLoaded(true))
      })
  }, [loaded, setLoaded, authorize, deauthorize, activateNetwork, networkActive, networkError])
  if (loaded) {
    return children
  }
  return <>Loading</>
}

export default MetamaskProvider