import { useCallback, useState } from "react"
import { useSnackbar } from "notistack"

import { Web3Provider } from "@ethersproject/providers"
import useEtherSWR from "ether-swr"
import { BigNumber, ethers } from "ethers"
import { splitSignature } from 'ethers/lib/utils'

import { lpContracts, stakingContracts } from "helpers/constants"


interface PermitProps {
  provider: Web3Provider
  chainId: number
  account: string
}

interface PermitData {
  amount: BigNumber
  deadline: number
  v: number
  r: string
  s: string
}

interface PermitState {
  loading: boolean
  nonceLoaded: boolean
  requestSignatures(amount: BigNumber): Promise<void>
  reset(): void
  permitData: PermitData | null
}

const UNI_V2_DOMAIN_DEFAULT = {
  name: 'Uniswap V2',
  version: '1',
}

const EIP712_DOMAIN_TYPE = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
]

const EIP2612_TYPE = [
  { name: 'owner', type: 'address' },
  { name: 'spender', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' },
]

function useLPTokenPermit({ provider, chainId, account }: PermitProps): PermitState {
  const spender = stakingContracts[chainId]
  const lpTokenAddress = lpContracts[chainId]
  const ethereum = provider.provider

  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [permitData, setPermitData] = useState<PermitData | null>(null)
  const { data: nonce } = useEtherSWR([lpTokenAddress, 'nonces', account])

  const nonceLoaded = typeof nonce !== 'undefined' ? true : false

  const reset = useCallback((): void => setPermitData(null), [setPermitData])

  const requestSignatures = useCallback(async (amount: BigNumber): Promise<void> => {
    if (typeof nonce === 'undefined') return

    const deadline = Math.floor(Date.now() / 1000) + 60 * 60 // 1 hour
    const domain = { ...UNI_V2_DOMAIN_DEFAULT, chainId, verifyingContract: lpTokenAddress }
    const message = {
      owner: account,
      spender,
      value: amount.toString(),
      nonce: nonce.toString(),
      deadline
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain: EIP712_DOMAIN_TYPE,
        Permit: EIP2612_TYPE,
      },
      domain,
      primaryType: 'Permit',
      message,
    })
    try {
      setLoading(true)
      const rawSignature = await ethereum.request?.({
        method: 'eth_signTypedData_v4',
        params: [account, data],
      })
      const signature = splitSignature(rawSignature)
      setPermitData({
        amount,
        deadline,
        v: signature.v,
        r: signature.r,
        s: signature.s,
      })
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'warning' })
    } finally {
      setLoading(false)
    }
  }, [nonce, chainId, account, provider])

  return {
    nonceLoaded,
    loading,
    requestSignatures,
    reset,
    permitData,
  }
}

export default useLPTokenPermit