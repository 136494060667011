interface ChainAddress {
  [chainId: number]: string;
}

export const stakingContracts: ChainAddress = {
  1: "0x74E0642de5D5bD24990961b72142884F28C4f359",
  3: "0x49BC40aa29c7DACB47dDE76781b5FB462627d954",
}

export const lpContracts: ChainAddress = {
  1: "0x1a4b5bf50e1efd384179c4a1277a3114e5ddcbd6",
  3: "0x91df2ce41edfbe3c7277dc844c49a58960bb661d",
}
