import { TokenInfo } from "@uniswap/token-lists";
import { ChainToken, IChainData } from "./types";
import supportedChains from "./chains";
import { ethers } from "ethers";


export const DEFAULT_CHAIN_ID = 1;


export function getChainData(chainId: number = DEFAULT_CHAIN_ID): IChainData {
  const chainData = supportedChains.filter(
    (chain: IChainData) => chain.chain_id === chainId
  )[0];

  if (!chainData) {
    throw new Error("ChainId missing or not supported");
  }

  const API_KEY = process.env.REACT_APP_INFURA_ID;

  if (
    chainData.rpc_url.includes("infura.io") &&
    chainData.rpc_url.includes("%API_KEY%") &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace("%API_KEY%", API_KEY);

    return {
      ...chainData,
      rpc_url: rpcUrl
    };
  }

  return chainData;
}


export function getToken(tokens: ChainToken | null, chainId: number, symbol: string): TokenInfo {
  const found = tokens && tokens[chainId] && tokens[chainId].find((token) => token.symbol === symbol && token.chainId === chainId)
  if (!found) throw Error(`Token "${symbol}" not found for chain id "${chainId}"`)
  return found
}


export async function prepareContractData(address: string, abi: any, fname: string, params: Array<any>): Promise<string | undefined> {
  const contract = new ethers.Contract(address, abi)
  const unsignedTx = await contract.populateTransaction[fname](...params)
  return unsignedTx.data
}

export function getShortAddress(address: string): string {
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
}