import React from 'react'
import { IconButton } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'utils/mui';


export const useStyles = makeStyles({
  iconButton: {
    marginLeft: '2px',
    width: '20px',
    height: '20px',
    '& svg': {
      width: '14px',
      height: '14px',
    }
  }
})


export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: colors.white,
    color: colors.oneFirstColor,
    border: `solid 2px ${colors.oneSecondColor}`,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    fontSize: 20,
    color: colors.white,
    '&:before': {
      border: `solid 2px ${colors.oneSecondColor}`,
    }
  }
}))(Tooltip);


interface TooltipButtonProps {
  text: string
  children?: any
  disabled?: boolean
  onClick?: any
}


export const TooltipButton = ({ children, onClick, text, disabled }: TooltipButtonProps): JSX.Element => {
  const classes = useStyles();
  return (
    <LightTooltip title={text} placement="right" arrow>
      <IconButton color="secondary" disableFocusRipple disableRipple className={classes.iconButton} disabled={disabled} onClick={onClick}>
        {children}
      </IconButton>
    </LightTooltip>
  )
}


export const ButtonHelp = ({ text }: { text: string }): JSX.Element => {
  return (
    <TooltipButton text={text}>
      <HelpOutlineIcon />
    </TooltipButton>
  )
}
