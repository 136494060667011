import { useEffect, useState } from "react";
import { BigNumber } from "ethers";

import useEtherSWR, { ethKeyInterface } from 'ether-swr';

import { lpContracts, stakingContracts } from 'helpers/constants'
import { LiquidityAction } from "helpers/types";

interface Data {
  chainId: number
  account: string
  action: LiquidityAction
}

function useLPBalanceOf({ chainId, account, action }: Data): BigNumber | undefined {
  const [balance, setBalance] = useState<BigNumber | undefined>()

  let params: ethKeyInterface;
  if (action === LiquidityAction.DEPOSIT) {
    params = [
      lpContracts[chainId],
      'balanceOf',
      account
    ]
  } else {
    params = [
      stakingContracts[chainId],
      'balanceOf',
      account
    ]
  }

  const { data } = useEtherSWR(params)

  useEffect(() => {
    if (!data) return

    setBalance(data)
  }, [data, chainId, account, action, setBalance])

  return balance
}

export default useLPBalanceOf