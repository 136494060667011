import { useCallback, useState } from "react"
import { useSnackbar } from "notistack";
import { getToken } from "helpers/utilities";
import useTokens from "./useTokens";


interface AddTokenData {
  loading: boolean
  addToken(chainId: number, symbol: string): Promise<void>
}


function useAddToken(): AddTokenData {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const tokens = useTokens()

  const addToken = useCallback(async (chainId: number, symbol: string): Promise<void> => {
    const token = getToken(tokens, chainId, symbol)
    console.log('token', token)
    try {
      setLoading(true)
      await window.ethereum.request?.({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
            image: token.logoURI,
          }
        },
      })
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'warning' })
    } finally {
      setLoading(false)
    }
  }, [tokens, enqueueSnackbar, setLoading])

  return {
    loading,
    addToken,
  }
}

export default useAddToken
