import { useCallback, useState } from "react"
import { useSnackbar } from "notistack";


interface SwitchNetworkData {
  loading: boolean
  switchNetwork(chainId: number): Promise<void>
}


function useSwitchNetwork(): SwitchNetworkData {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const switchNetwork = useCallback(async (chainId: number): Promise<void> => {
    try {
      setLoading(true)
      await window.ethereum.request?.({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      enqueueSnackbar(`Network changed to "${chainId}"!`, { variant: 'success' })
    } catch (e) {
      const errMsg = (e as Error).message
      enqueueSnackbar(errMsg, { variant: 'warning' })
    } finally {
      setLoading(false)
    }
  }, [enqueueSnackbar, setLoading])

  return {
    loading,
    switchNetwork,
  }
}

export default useSwitchNetwork
