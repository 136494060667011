import { configureStore } from '@reduxjs/toolkit'

import walletSlice from 'features/wallet/walletSlice'
import poolSlice from 'features/wallet/poolSlice'
import modalSlice from 'features/wallet/modalSlice'


const localStorageMiddleware = ({ getState }) => {
  return next => action => {
    const result = next(action)
    localStorage.setItem('applicationState', JSON.stringify(getState()))
    return result
  }
}


const reHydrateStore = () => {
  const state = localStorage.getItem('applicationState')
  if (state !== null) {
    const parsedState = JSON.parse(state) // re-hydrate the store
    // dirty
    parsedState.modal.opened = false
    parsedState.pool.loaded = false
    parsedState.pool.isAuthorized = false
    return parsedState
  }
}

export const store = configureStore({
  reducer: {
    wallet: walletSlice,
    pool: poolSlice,
    modal: modalSlice,
  },
  preloadedState: reHydrateStore(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(localStorageMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
