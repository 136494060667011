import { useEffect, useState } from 'react'

import useEtherSWR from 'ether-swr';
import { BigNumber } from 'ethers';
import { parseUnits, formatUnits } from 'ethers/lib/utils'

import { ChainToken } from 'helpers/types';
import { stakingContracts, lpContracts } from 'helpers/constants'
import { getToken } from 'helpers/utilities';


interface ContractInfo {
  liquidityShare: number;
  accountRewards: number;
  uTvl: number;
  xTvl: number;
  tvl: number;
  price: number;
  apr: number;
  earned: BigNumber;
}


function useContractInfo(chainId: number, account: string, tokens: ChainToken): ContractInfo | null {
  const [contractInfo, setContractInfo] = useState<ContractInfo | null>(null)

  const soltToken = getToken(tokens, chainId, 'sOLT')
  const usdtToken = getToken(tokens, chainId, 'USDT')

  const { data } = useEtherSWR([
    [lpContracts[chainId], 'getReserves'],
    [lpContracts[chainId], 'token0'],
    [lpContracts[chainId], 'token1'],
    [lpContracts[chainId], 'balanceOf', stakingContracts[chainId]],
    [lpContracts[chainId], 'totalSupply'],

    [stakingContracts[chainId], 'balanceOf', account],
    [stakingContracts[chainId], 'totalSupply'],
    [stakingContracts[chainId], 'earned', soltToken.address, account],
    [stakingContracts[chainId], 'rewardTokenInfo', soltToken.address],
  ])

  useEffect(() => {
    if (!data) return;

    const [
      reserves,
      token0,
      token1,
      poolBalance,
      lpBalance,
      currentStakingBalance,
      totalSupply,
      earned,
      rewardTokenInfo,
    ] = data
    const [reserve0, reserve1]: [BigNumber, BigNumber] = reserves;

    let price = 0, tvl = 0;
    const fraction = parseUnits('1', soltToken.decimals)
      .div(parseUnits('1', usdtToken.decimals))
    const divider = parseUnits('1', 5)

    const getPrice = (revPrice: BigNumber, fraction: BigNumber, divider: BigNumber): number => {
      return +(((1 / revPrice.toNumber()) * fraction.toNumber() / divider.toNumber()).toFixed(5))
    }

    if (soltToken.address.toLowerCase() === token0.toLowerCase()) {
      price = getPrice(reserve0.div(reserve1).div(divider), fraction, divider)
      tvl = +formatUnits(reserve0, soltToken.decimals) * price + +formatUnits(reserve1, usdtToken.decimals)
    } else if (soltToken.address.toLowerCase() === token1.toLowerCase()) {
      price = getPrice(reserve1.div(reserve0).div(divider), fraction, divider)
      tvl = +formatUnits(reserve1, soltToken.decimals) * price + +formatUnits(reserve0, usdtToken.decimals)
    }
    const xTvl = +(tvl * poolBalance.mul(divider).div(lpBalance).toNumber() / divider.toNumber()).toFixed(2)
    const uTvl = +(tvl * currentStakingBalance.mul(divider).div(lpBalance).toNumber() / divider.toNumber()).toFixed(2)

    const liquidityShare = totalSupply.gt(0) ? +((currentStakingBalance.mul(divider.toNumber()).div(totalSupply).toNumber() / 1000).toFixed(2)) : 0
    const accountRewards = +((+formatUnits(earned, soltToken.decimals)).toFixed(4))

    const { rewardRate, lastUpdateTime, periodFinish } = rewardTokenInfo;
    const distributionPeriodDays = Math.round((periodFinish - lastUpdateTime) / (3600 * 24))
    const totalRewards = Math.round(+formatUnits(rewardRate, soltToken.decimals) * 3600 * 24 * distributionPeriodDays)

    const apr = +((totalRewards * price * 365 * 100 / (xTvl * distributionPeriodDays)).toFixed(2)) || Infinity

    setContractInfo({ liquidityShare, accountRewards, xTvl, uTvl, tvl, price, apr, earned })

  }, [data])

  return contractInfo
}

export default useContractInfo