import { TokenInfo } from "@uniswap/token-lists";

export interface IAssetData {
  symbol: string;
  name: string;
  decimals: string;
  contractAddress: string;
  balance?: string;
}

export interface IChainData {
  name: string;
  short_name: string;
  chain: string;
  network: string;
  chain_id: number;
  network_id: number;
  rpc_url: string;
  explorer_url: string;
  native_currency: IAssetData;
}

export interface ChainToken {
  [chainId: number]: TokenInfo[];
}

export enum LiquidityAction {
  DEPOSIT = 1,
  WITHDRAW = 2
}
