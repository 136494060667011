import React, { useEffect, useMemo, useCallback, ChangeEvent } from 'react'
import { Button, ButtonGroup, Grid, Link } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress'

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumber, ethers } from 'ethers';

import { useAppDispatch } from 'app/hooks'
import { deposit, withdraw, exit } from 'features/wallet/poolSlice'

import { useStyles } from './styles'
import { ChainToken, LiquidityAction } from 'helpers/types';
import { useState } from 'react';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import useLPBalanceOf from 'helpers/hooks/useLPBalanceOf';
import { getToken } from 'helpers/utilities';
import usePoolTransaction from 'helpers/hooks/usePoolTransaction';
import useLPTokenPermit from 'helpers/hooks/useLPTokenPermit';
import { colors } from 'utils/mui';
import useAddToken from 'helpers/hooks/useAddToken';
import MetaMaskIcon from '../../assets/icons/wallets/MetaMaskIcon.png'


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`liquidity-tabpanel-${index}`}
      aria-labelledby={`liquidity-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number, value: number) => {
  return {
    id: `liquidity-tab-${index}`,
    'aria-controls': `liquidity-tabpanel-${index}`,
    style: {
      color: value !== index ? colors.oneSecondColor : colors.white,
    }
  };
}

interface FormProps {
  chainId: number
  provider: Web3Provider
  account: string
  action: LiquidityAction
}

enum StakeStateCheck {
  DEFAULT = 0,
  INSUFFICIENT_BALANCE = 1,
  REQUIRED_ALLOWANCE = 2,
  READY_TO_SUBMIT = 3
}

const LiquidityForm = ({ provider, chainId, account, action }: FormProps): JSX.Element => {
  const { textFieldInput, textFieldNotchedOutline, textFieldLabel, formBox, stakeButton, maxButton, loaderButton } = useStyles()

  const [amount, setAmount] = useState<BigNumber | undefined>()
  const dispatch = useAppDispatch()

  const { loading: sigLoading, nonceLoaded, requestSignatures, permitData, reset: resetPermit } = useLPTokenPermit({ provider, chainId, account })
  const refresh = useCallback(() => {
    setAmount(undefined)
    resetPermit()
  }, [setAmount, resetPermit])

  const { loading: txLoading, execTransaction } = usePoolTransaction({ provider, chainId, account })
  const loading = txLoading || sigLoading || !nonceLoaded

  const currentBalance = useLPBalanceOf({ chainId, account, action })

  useEffect(() => {
    setAmount(undefined)
  }, [action, account, chainId, currentBalance, setAmount])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target

    value = +value >= 0 ? value : '';

    if (value.includes('-') || value.length > 32)
      return

    const splitedV = value.split('.')[1];
    if (splitedV && splitedV.length > 18)
      return

    setAmount(value ? parseUnits(value, 18) : undefined)
    // should reset permit data if it exists
    if (permitData) {
      resetPermit()
    }
  }

  const pasteMaxAmount = () => currentBalance && currentBalance.gt(0) && setAmount(currentBalance)

  const getStepState = () => {
    if (
      typeof amount === 'undefined' ||
      typeof currentBalance === 'undefined' ||
      amount.eq(ethers.constants.Zero)
    ) {
      return StakeStateCheck.DEFAULT
    } else if (currentBalance.lt(amount)) {
      return StakeStateCheck.INSUFFICIENT_BALANCE
    } else if (action === LiquidityAction.DEPOSIT && !permitData) {
      return StakeStateCheck.REQUIRED_ALLOWANCE
    }
    return StakeStateCheck.READY_TO_SUBMIT
  }

  // approve
  const onApprove = async () => amount && await requestSignatures(amount)

  // stake methods
  const onStake = async () => {
    if (permitData) {
      await execTransaction({
        method: 'stakeWithPermit',
        params: [
          permitData.amount,
          permitData.deadline,
          permitData.v,
          permitData.r,
          permitData.s,
        ],
        onSuccess: (hash) => {
          dispatch(deposit({ hash, amount: permitData.amount, chainId }))
          refresh()
        }
      })
    } else {
      await execTransaction({
        method: 'stake',
        params: [amount],
        onSuccess: (hash) => {
          dispatch(deposit({ hash, amount, chainId }))
          refresh()
        }
      })
    }
  }

  // exit methods
  const onExit = async () => await execTransaction({
    method: 'exit',
    onSuccess: (hash) => {
      dispatch(exit({ hash, amount, chainId }))
      refresh()
    }
  })
  const onWithdraw = async () => await execTransaction({
    method: 'withdraw',
    params: [amount],
    onSuccess: (hash) => {
      dispatch(withdraw({ hash, amount, chainId }))
      refresh()
    }
  })

  // auto detect if required full exit or withdraw partially
  const onAutoWithdraw = async () => {
    if (amount === currentBalance)
      await onExit()
    else
      await onWithdraw()
  }

  const stateButton = useMemo(() => {
    switch (getStepState()) {
      case StakeStateCheck.DEFAULT:
        return <Button className={stakeButton} fullWidth disabled>Enter amount</Button>
      case StakeStateCheck.INSUFFICIENT_BALANCE:
        return <Button className={stakeButton} fullWidth disabled>Insufficient amount</Button>
      case StakeStateCheck.REQUIRED_ALLOWANCE:
        return (
          <ButtonGroup fullWidth>
            <Button className={stakeButton} onClick={onApprove}>Approve</Button>
            <Button className={stakeButton} disabled>Deposit</Button>
          </ButtonGroup>
        )
      case StakeStateCheck.READY_TO_SUBMIT:
        return (
          action === LiquidityAction.DEPOSIT ? (
            <ButtonGroup fullWidth>
              <Button className={stakeButton} fullWidth disabled>Approved</Button>
              <Button className={stakeButton} onClick={onStake} fullWidth>Deposit</Button>
            </ButtonGroup>
          ) : (
            <Button className={stakeButton} onClick={onAutoWithdraw} fullWidth>Withdraw</Button>
          )
        )
    }
  }, [amount, currentBalance, permitData, loading, action])

  const loadingButton = useMemo(() => {
    switch (getStepState()) {
      case StakeStateCheck.REQUIRED_ALLOWANCE:
        return (
          <ButtonGroup fullWidth>
            <Button style={{ backgroundColor: 'transparent' }} className={stakeButton} disabled>
              <CircularProgress classes={{ colorPrimary: loaderButton }} />
            </Button>
            <Button className={stakeButton} disabled>Deposit</Button>
          </ButtonGroup>
        )
      case StakeStateCheck.READY_TO_SUBMIT:
        return (
          action === LiquidityAction.DEPOSIT ? (
            <ButtonGroup fullWidth>
              <Button className={stakeButton} fullWidth disabled>Approved</Button>
              <Button style={{ backgroundColor: 'transparent' }} className={stakeButton} disabled>
                <CircularProgress classes={{ colorPrimary: loaderButton }} />
              </Button>
            </ButtonGroup>
          ) : (
            <Button style={{ backgroundColor: 'transparent' }} className={stakeButton} fullWidth disabled>
              <CircularProgress classes={{ colorPrimary: loaderButton }} />
            </Button>
          )
        )
      default:
        return (
          <Button style={{ backgroundColor: 'transparent' }} className={stakeButton} fullWidth disabled>
            <CircularProgress classes={{ colorPrimary: loaderButton }} />
          </Button>
        )
    }
  }, [amount, currentBalance, permitData, loading, action])

  return (
    <form noValidate autoComplete="off" className={formBox}>
      <div>
        <TextField
          id="stake-amount"
          label="Amount (LP)"
          type="number"
          placeholder="0.0"
          variant="outlined"
          value={amount ? +formatUnits(amount, 18) : ""}
          onChange={handleChange}
          InputProps={{
            classes: {
              input: textFieldInput,
              notchedOutline: textFieldNotchedOutline
            },
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: textFieldLabel,
            }
          }}
        />
        <Button aria-label="max amount" className={maxButton} onClick={pasteMaxAmount}>
          Max
        </Button>
        {loading ? (
          <>
            {loadingButton}
          </>
        ) : (
          <>
            {stateButton}
          </>
        )}
      </div>
    </form >
  )
}

interface InfoProps {
  chainId: number;
  account: string;
  tokens: ChainToken;
}

export const LiquidityTab = ({
  chainId,
  account,
  tokens
}: InfoProps): JSX.Element => {
  const { tabBox, loaderBox, slpLink } = useStyles()

  const { library: provider } = useWeb3React<Web3Provider>()
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };
  const { addToken } = useAddToken()

  const soltToken = getToken(tokens, chainId, 'sOLT')
  const usdtToken = getToken(tokens, chainId, 'USDT')

  if (!provider)
    return (
      <Grid item xs={12} className={tabBox}>
        <CircularProgress classes={{ colorPrimary: loaderBox }} />
      </Grid>
    )

  return (
    <Grid item xs={12} className={tabBox}>
      <Box p={1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Link
          color="secondary"
          href={`https://syndicate-bridge.oneledger.network`}
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
          className={slpLink}
        >
          1. Get sOLT through the bridge
        </Link>
        <Link
          color="secondary"
          href={`#`}
          onClick={() => { addToken(chainId, 'sOLT') }}
          underline="always"
          className={slpLink}
        >
          2. Add sOLT to MetaMask <img src={MetaMaskIcon} height={12} />
        </Link>
        <Link
          color="secondary"
          href={`https://app.uniswap.org/#/add/v2/${usdtToken.address}/${soltToken.address}`}
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
          className={slpLink}
        >
          3. Add sOLT-USDT LP on UniSwap
        </Link>
        <div className={slpLink}>4. Deposit sOLT-USDT LP to the pool</div>
      </Box>
      <AppBar position="static" color="transparent">
        <Tabs centered value={value} onChange={handleChange} aria-label="liquidity staking">
          <Tab label="Deposit" {...a11yProps(0, value)} />
          <Tab label="Withdraw" {...a11yProps(1, value)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <LiquidityForm provider={provider} chainId={chainId} account={account} action={LiquidityAction.DEPOSIT} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LiquidityForm provider={provider} chainId={chainId} account={account} action={LiquidityAction.WITHDRAW} />
      </TabPanel>
    </Grid>
  )
}
