import React from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Web3Provider } from '@ethersproject/providers';

import { useAppDispatch } from 'app/hooks'
import { ModalType, openModal } from 'features/wallet/modalSlice'

import { claim } from 'features/wallet/poolSlice'
import { useContractInfo } from 'helpers/hooks'
import { ChainToken } from 'helpers/types'
import { ButtonHelp, LightTooltip } from 'components/tooltips'

import { useStyles } from './styles'
import { BigNumber, ethers } from 'ethers';
import usePoolTransaction from 'helpers/hooks/usePoolTransaction'
import supportedChains from 'helpers/chains'
import { colors } from 'utils/mui'
import useSwitchNetwork from 'helpers/hooks/useSwitchNetwork'


interface InfoProps {
  provider: Web3Provider
  chainId: number
  account: string
  tokens: ChainToken
}

export const IntroInfo = (): JSX.Element => {
  const { itemBoxVer, stakeButton } = useStyles()
  const dispatch = useAppDispatch()
  return (
    <Grid item xs={12} className={itemBoxVer} style={{ flexDirection: 'column' }}>
      <Typography variant="h4" paragraph style={{ color: colors.darkRed }}>Connect wallet</Typography>
      <Typography paragraph>To continue, please connect your wallet</Typography>
      <Button
        className={stakeButton}
        style={{ padding: '12px 24px', marginBottom: '12px' }}
        fullWidth
        onClick={() => dispatch(openModal(ModalType.CONNECTION_SELECT))}
      >
        Connect wallet
      </Button>
    </Grid>
  )
}


export const UnsupportedInfo = (): JSX.Element => {
  const { itemBoxVer, stakeButton, loaderButton } = useStyles()
  const { loading, switchNetwork } = useSwitchNetwork()

  return (
    <Grid item xs={12} className={itemBoxVer} style={{ flexDirection: 'column' }}>
      <Typography variant="h4" paragraph style={{ color: colors.darkRed }}>Invalid network</Typography>
      <Typography paragraph>Please connect to one of the appropriate EVM network</Typography>
      {loading ? (
        <div>
          <CircularProgress classes={{ colorPrimary: loaderButton }} />
        </div>
      ) : (
        <div>
          {supportedChains.map((chain) => (
            <Button
              key={chain.chain_id}
              className={stakeButton}
              style={{ padding: '12px 24px', marginBottom: '12px' }}
              fullWidth
              onClick={() => switchNetwork(chain.chain_id)}
            >
              {chain.name}
            </Button>
          ))}
        </div>
      )}
    </Grid>
  )
}


export const TVLInfo = ({
  chainId,
  account,
  tokens
}: InfoProps): JSX.Element => {
  const { itemBoxVer, loaderBox, textBox } = useStyles()

  const contractInfo = useContractInfo(chainId, account, tokens)

  if (!contractInfo)
    return (
      <Grid item xs={12} className={itemBoxVer} style={{ justifyContent: 'center' }}>
        <CircularProgress classes={{ colorPrimary: loaderBox }} />
      </Grid>
    )

  const { xTvl, price } = contractInfo;

  return (
    <Grid item xs={12} className={itemBoxVer}>
      <Box flexDirection="column" style={{ minWidth: '52%' }}>
        <Box style={{ textAlign: 'left', display: 'flex' }}>
          <Typography>TVL</Typography>
          <ButtonHelp text="Total value locked in liquidity mining programs" />
        </Box>
        <Box className={textBox}>$ {+(xTvl.toFixed(2))}</Box>
      </Box>
      <Divider orientation="vertical" flexItem style={{ backgroundColor: '#5cf5cf' }} />
      <Box flexDirection="column">
        <Box style={{ textAlign: 'left' }}>
          <Typography><span style={{ color: '#5cf5cf' }}>sOLT</span> Price</Typography>
        </Box>
        <Box className={textBox} style={{ paddingRight: '40px' }}>$ {price.toFixed(4)}</Box>
      </Box>
    </Grid>
  )
}

interface ClaimProps {
  provider: Web3Provider
  earned: BigNumber
  chainId: number;
  account: string;
}

const ButtonClaim = ({ provider, earned, chainId, account }: ClaimProps): JSX.Element => {
  const { claimButton } = useStyles()

  const dispatch = useAppDispatch()
  const disabled = earned.eq(ethers.constants.Zero);
  const { loading, execTransaction } = usePoolTransaction({ provider, chainId, account })

  const onGetRewards = async () => await execTransaction({
    method: 'getRewards',
    onSuccess: (hash) => {
      dispatch(claim({ hash, chainId }))
    }
  })
  return (
    <>
      {loading ? (
        <Button color="secondary" variant="outlined" className={claimButton} disabled>
          <CircularProgress color="secondary" style={{
            width: '10px',
            height: '10px',
          }} />
        </Button>
      ) : (
        <LightTooltip title={"Claim your accumulated rewards"} placement="right" arrow>
          <span>
            <Button color="secondary" variant="outlined" className={claimButton} onClick={onGetRewards} disabled={disabled}>Claim</Button>
          </span>
        </LightTooltip>
      )}
    </>
  )
}

export const RewardInfo = ({
  provider,
  chainId,
  account,
  tokens
}: InfoProps): JSX.Element => {
  const { itemBoxHor, loaderBox, textBox } = useStyles()

  const contractInfo = useContractInfo(chainId, account, tokens)

  if (!contractInfo)
    return (
      <Grid item xs={12} className={itemBoxHor} style={{ justifyContent: 'center' }}>
        <CircularProgress classes={{ colorPrimary: loaderBox }} />
      </Grid>
    )

  const { liquidityShare, accountRewards, apr, uTvl, earned } = contractInfo

  return (
    <Grid item xs={12} className={itemBoxHor} style={{ flexDirection: 'column' }}>
      <Box flexDirection="row" display="flex" style={{ justifyContent: 'space-between' }}>
        <Box flexDirection="column" style={{ textAlign: 'left', minWidth: '146px' }}>
          <Box style={{ display: 'flex' }}>
            <Typography>Your Rewards</Typography>
            <ButtonClaim provider={provider} earned={earned} chainId={chainId} account={account} />
          </Box>
          <Box className={textBox}>
            {accountRewards}
          </Box>
        </Box>
        <Box flexDirection="column" style={{ textAlign: 'left', minWidth: '146px' }}>
          <Box style={{ display: 'flex' }}>
            <Typography>Pool APR</Typography>
            <ButtonHelp text="APR to the pool denominated in USD" />
          </Box>
          <Box className={textBox} style={{ color: '#5cf5cf' }}>
            {apr} %
          </Box>
        </Box>
      </Box>
      <Divider style={{ backgroundColor: '#5cf5cf', margin: '10px 0' }} />
      <Box flexDirection="row" display="flex" style={{ justifyContent: 'space-between' }}>
        <Box flexDirection="column" style={{ textAlign: 'left', minWidth: '146px' }}>
          <Box>
            <Typography>Your Liquidity</Typography>
          </Box>
          <Box className={textBox}>$ {uTvl}</Box>
        </Box>
        <Box flexDirection="column" style={{ textAlign: 'left', minWidth: '146px' }}>
          <Box style={{ display: 'flex' }}>
            <Typography>Liquidity Share</Typography>
            <ButtonHelp text="Your percentage ownership of the liquidity pool" />
          </Box>
          <Box className={textBox} style={{ color: '#5cf5cf' }}>{liquidityShare} %</Box>
        </Box>
      </Box>
    </Grid>
  )
}
