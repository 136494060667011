import React, { useState, useEffect, useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  Typography,
  IconButton,
  Grid,
  Link,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { closeModal, ModalType } from 'features/wallet/modalSlice'
import { clearReceipts, TxStatusType, TxType } from 'features/wallet/poolSlice'

import { colors } from 'utils/mui'
import MetaMaskIcon from '../../assets/icons/wallets/MetaMaskIcon.png'
import { connectorsByName, ConnectorNames } from '../../helpers/connectors'
import { getChainData, getShortAddress } from 'helpers/utilities'
import useAddToken from 'helpers/hooks/useAddToken'
import { IChainData } from 'helpers/types'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'


const useStyles = makeStyles({
  walletSelectModal: {
    borderRadius: '10px',
  },
  walletSelectButton: {
    textTransform: 'none',
    borderRadius: '10px',
    justifyContent: 'space-between',
    padding: '20px 20px 20px 20px',
  },
  termsNotification: {
    borderRadius: '10px',
    backgroundColor: colors.oneFirstColor,
    color: colors.white,
    padding: '10px',
    fontSize: '1rem',
    '& > p': {
      fontSize: '0.8rem',
    }
  },
  errorButton: {
    backgroundColor: colors.darkRed + '!important',
    color: colors.white + '!important',
    borderColor: colors.brightRed + '!important',
  },
})


const styles = (theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton focusRipple={false} aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export const WalletSelectModal = (): JSX.Element => {
  const { walletSelectButton, walletSelectModal, termsNotification, errorButton } = useStyles()
  const { account, activate, error: providerError } = useWeb3React<Web3Provider>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [lastConnector, setLastConnector] = useState('' as ConnectorNames)

  const dispatch = useAppDispatch()
  const modalOpened = useAppSelector((state) => state.modal.opened && state.modal.type === ModalType.CONNECTION_SELECT)

  const handleClose = () => dispatch(closeModal())

  const onConnect = (type: ConnectorNames) => async () => {
    setLoading(true)
    setLastConnector(type)
    try {
      await activate(connectorsByName[type])
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (account) {
      setLoading(false)
    }
  }, [account, setLoading]);

  useEffect(() => {
    if (providerError) {
      setLoading(false)
      setError(providerError.name)
    }
  }, [providerError, setLoading, setError]);

  return (
    <Dialog
      open={modalOpened}
      onClose={handleClose}
      aria-labelledby="wallet-dialog-title"
      maxWidth={'xs'}
      fullWidth
      classes={{
        paper: walletSelectModal
      }}
    >
      <DialogTitle id="wallet-dialog-title" onClose={handleClose}>
        Connect a wallet
      </DialogTitle>
      <DialogContent dividers>
        <List>
          <ListItem>
            <Paper variant="outlined" className={termsNotification}>
              <Typography>
                By connecting a wallet, you agree to OneLedger Inc’ Terms of Service and acknowledge that you have read and understand the Rewards Protocol Disclaimer.
              </Typography>
            </Paper>
          </ListItem>
          <>
            {loading ? (
              <ListItem>
                <Button className={walletSelectButton} variant="outlined" fullWidth disabled>
                  <Typography>Initializing...</Typography>
                  <CircularProgress color="secondary" style={{ height: '24px', width: '24px' }} />
                </Button>
              </ListItem>
            ) : (
              <>
                {error ? (
                  <ListItem>
                    <Button className={`${walletSelectButton} ${errorButton}`} variant="outlined" fullWidth onClick={onConnect(lastConnector)}>
                      <Typography>Error connecting, please try again</Typography>
                    </Button>
                  </ListItem>
                ) : (
                  <ListItem>
                    <Button className={walletSelectButton} variant="outlined" fullWidth onClick={onConnect(ConnectorNames.Injected)}>
                      <Typography>MetaMask</Typography>
                      <img src={MetaMaskIcon} style={{ height: '24px' }} />
                    </Button>
                  </ListItem>
                )}
              </>
            )}
          </>
        </List>
      </DialogContent >
    </Dialog >
  )
}


const CopyAddressLink = React.memo(({ address }: { address: string }): JSX.Element => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!copied) return

    const id = setTimeout(() => setCopied(false), 500)
    return () => {
      clearTimeout(id)
    }
  }, [copied, setCopied])

  return (
    <>
      {copied ? (
        <Link href="#" color="secondary">
          <CheckCircleOutlinedIcon style={{ width: 12, height: 12, paddingRight: 4 }} />
          Copied
        </Link>
      ) : (
        <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
          <Link href="#" color="secondary">
            <FileCopyOutlinedIcon style={{ width: 12, height: 12, paddingRight: 4 }} />
            Copy address
          </Link>
        </CopyToClipboard>
      )}
    </>
  )
})
CopyAddressLink.displayName = 'CopyAddressLink'


const StatusIcon = ({ status }: { status: TxStatusType }): JSX.Element | null => {
  switch (status) {
    case TxStatusType.PENDING:
      return <CircularProgress style={{ color: colors.oneFirstColor, width: 20, height: 20 }} />
    case TxStatusType.DONE:
      return <CheckCircleOutlinedIcon style={{ fill: colors.coolGreen, width: 20, height: 20 }} />
    case TxStatusType.FAILED:
      return <ErrorOutlineOutlinedIcon style={{ fill: colors.darkRed, width: 20, height: 20 }} />
    default:
      return null
  }
}

const TxItem = ({ hash, amount, type, currentNetwork }: { hash: string, amount?: BigNumber, type: TxType, currentNetwork: IChainData }): JSX.Element | null => {
  switch (type) {
    case TxType.CLAIM:
      return (
        <Link href={`${currentNetwork.explorer_url}/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {amount ? (
            <>
              Rewards claimed {+(+formatUnits(amount, 18)).toFixed(4)} sOLT
            </>
          ) : (
            <>
              Claiming rewards...
            </>
          )}
        </Link>
      )
    case TxType.DEPOSIT:
      return (
        <Link href={`${currentNetwork.explorer_url}/tx/${hash}`} target="_blank" rel="noopener noreferrer">Deposit {amount && +(+formatUnits(amount, 18)).toFixed(4)} UNI LP</Link>
      )
    case TxType.EXIT:
    case TxType.WITHDRAW:
      return (
        <Link href={`${currentNetwork.explorer_url}/tx/${hash}`} target="_blank" rel="noopener noreferrer">Withdraw {amount && +(+formatUnits(amount, 18)).toFixed(4)} UNI LP</Link>
      )
    default:
      return null
  }
}


export const WalletInfoModal = (): JSX.Element => {
  const { walletSelectModal } = useStyles()
  const dispatch = useAppDispatch()
  const modalOpened = useAppSelector((state) => state.modal.opened && state.modal.type === ModalType.WALLET_INFO)
  const { account, chainId } = useWeb3React<Web3Provider>()
  const txs = useAppSelector((state) => Object.keys(state.pool.txs)
    .filter((hash) => state.pool.txs[hash].chainId === chainId)
    .map((hash) => state.pool.txs[hash]))
  const { addToken } = useAddToken()

  const currentNetwork = useMemo(() => getChainData(chainId), [chainId])

  const handleClose = () => dispatch(closeModal())
  const clearTxs = () => chainId && dispatch(clearReceipts(chainId))

  return (
    <Dialog
      open={modalOpened}
      onClose={handleClose}
      aria-labelledby="wallet-info-dialog-title"
      maxWidth={'xs'}
      fullWidth
      classes={{
        paper: walletSelectModal
      }}
    >
      <DialogTitle id="wallet-info-dialog-title" onClose={handleClose}>
        Account
      </DialogTitle>
      <DialogContent>
        <Paper elevation={1} variant="outlined" style={{ padding: 12, borderRadius: 12, borderColor: colors.coolBlack, backgroundColor: colors.coolBlack }}>
          {account && chainId && (
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ color: colors.white }}>Connected with MetaMask</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary" style={{ fontWeight: 600, color: colors.white }}>{getShortAddress(account)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <CopyAddressLink address={account} />
                <Link color="secondary" target="_blank" rel="noopener noreferrer" href={`${currentNetwork.explorer_url}/address/${account}`} style={{ paddingLeft: 24 }}><OpenInNewOutlinedIcon style={{ width: 12, height: 12, paddingRight: 4 }} />View on Explorer</Link>
                <Button variant="outlined" color="secondary" style={{ padding: 6, borderRadius: 12, marginTop: 12, color: colors.white }} onClick={() => addToken(chainId, 'sOLT')}>
                  <img src="https://raw.githubusercontent.com/Oneledger/syndicate-token-list/master/assets/asset_sOLT.svg" height={20} style={{ marginRight: 6 }} />
                  Add sOLT to MetaMask
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </DialogContent>
      <DialogContent style={{ backgroundColor: '#edeef2' }}>
        <Box style={{ padding: 12 }}>
          <Grid container spacing={3}>
            {!txs.length ? (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Your transactions will appear here...</Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Recent Transactions</Typography>
                  <Link href="#" onClick={clearTxs}>(clear all)</Link>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {txs.map((tx) => (
                      <Grid item xs={12} key={tx.hash} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 6 }}>
                        <TxItem currentNetwork={currentNetwork} {...tx} />
                        <StatusIcon status={tx.status} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog >
  )
}